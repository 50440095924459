module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.13.1_babel-plugin-styled-components@2.1.4_@babel+core_07323c665b9c65b50abc86eb372936e0/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-twitter@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.1__react-dom_0657bd8973192a53a436a5cd60e6b509/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-gatsby-cloud@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.1__reac_265a3e25ec8374fb6237a43e9c1fcad2/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/static/*":["Cache-Control: public, max-age=31536000, immutable"],"/fonts/*":["Cache-Control: public, max-age=31536000, immutable"],"/*.svg":["Cache-Control: public, max-age=31536000, immutable"]}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-react-i18next@3.0.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.1__reac_34c1d4cfc4cc3e67d76282b0c651ddab/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["it","en","fr"],"defaultLanguage":"it","fallbackLanguage":"it","siteUrl":"https://academy.youngplatform.com/","redirect":false,"i18nextOptions":{"nsSeparator":false,"redirect":false,"generateDefaultLanguagePage":true,"fallbackLng":"it","wait":true,"detection":{"order":["path","localStorage","cookie","navigator"]}},"pages":[{"matchPath":"/:lang?/:category/:page?","getLanguageFromPath":true},{"matchPath":"/:lang?/tag/:slug/:page?/","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.1__react-do_29d99be583074924f4589218de6aa6e1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"young-platform-academy","short_name":"academy","start_url":"/","background_color":"#8D59FF","display":"minimal-ui","icon":"src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d3244fa4571a3f413dfffaa94205bf4e"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.1__c59a193a8f2bfece7304de18e57408db/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N3H7Q8B","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.1__react_791bf7f5831ca99cc58f5046a00a54ff/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-source-wordpress@7.13.5_aguldmsyvpquhvv2fi4wqfdy6e/node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://academyapi2.youngplatform.com/graphql","verbose":true,"html":{"useGatsbyImage":true,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"MediaItem":{"createFileNodes":false,"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"Post":{"limit":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"schema":{"perPage":20,"requestConcurrency":3,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000,"previewRequestConcurrency":5},"production":{"allow404Images":true,"hardCacheMediaFiles":false,"allow401Images":false},"debug":{"graphql":{"showQueryVarsOnError":true,"printIntrospectionDiff":true,"showQueryOnError":true,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"catchLinks":true},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18.3.1_react@18.3.1__react@1_70ceb73562a694cfc6906e258cdb1f16/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
